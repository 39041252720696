




import { Vue, Component, Prop } from 'vue-property-decorator'
import Report from './components/Report.vue'
import MonthlyReport from './components/MonthlyReport.vue'
import { getSchoolInfo } from '@/utils/utils'

@Component({
  components: {
    Report,
    MonthlyReport,
  },
})
export default class ReportMgm extends Vue {
  private componentList: any = {
    2: 'Report',
    8: 'MonthlyReport',
    default: 'Report',
  }

  private get schoolId(): string {
    return getSchoolInfo().schoolId?.toString()
  }

  private get realComponent(): String {
    return Object.keys(this.componentList).includes(this.schoolId)
      ? this.componentList[this.schoolId]
      : this.componentList.default
  }
}
