

























































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {
  StudentController,
  DropDownController,
  TaskGradeController,
  GradeController,
} from '@/services/request.service'
import PreviewModal from '@/components/Report/PreviewModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { getSchoolInfo, getToken } from '@/utils/utils'
import EventBus from '@/utils/EventBus'
import saveAs from '@/utils/FileSaver.js'
import moment from 'moment'
import { waitFor } from '@/constant/constant'
const operations: any = {
  high: function(periodId) {
    return GradeController.getUpperSchoolReport(periodId)
  },
  middle: function(periodId) {
    return GradeController.getMidSchoolReport(periodId)
  },
  elementary: function(periodId) {
    return GradeController.getGradeReportDetail(periodId)
  },
  middleYear: function(periodId) {
    return GradeController.getMidSchoolAnnualReport(periodId)
  },
  highYear: function(periodId) {
    return GradeController.getUpperSchoolAnnualReport(periodId)
  },
}

@Component({
  components: {
    FlexTooltip,
    PreviewModal,
  },
})
export default class Report extends Vue {
  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYears: Array<any> = []
  private data: any = []
  private previewId: any = -1
  private campusCode: any = {
    1231: 'elementary',
    1232: 'middle',
    1233: 'high',
  }
  private campusType: any = 'elementary'
  private reportData: any = {}
  private previewing: any = false
  private previewVisible: any = false
  private reportType: any = 'term'
  private schoolYearType: any = '2021-2022'

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        ellipsis: true,
        title: this.$t('student.reportName'),
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'schoolYear',
        title: this.$t('common.schoolYear'),
        dataIndex: 'schoolYear',
      },
      // {
      //   key: "gradePeriod",
      //   title: this.$tc("student.gradePeriod"),
      //   dataIndex: "gradePeriodName",
      // },
      {
        dataIndex: 'gradePeriodType',
        title: this.$t('student.reportType'),
        scopedSlots: { customRender: 'reportType' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get studentId(): any {
    return this.$store.state.memberId ? parseInt(this.$store.state.memberId, 10) : undefined
  }

  private get studentName(): any {
    const { firstName, lastName, enName, name } = this.$store.state.userInfo
    return `${lastName} ${enName} ${firstName} ${name}`
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        this.$set(this.filter, 'schoolYearId', res.data[0].key)
        this.schoolYearType = res.data[0].value
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeYear(): void {
    this.schoolYearType = this.schoolYears.find(item => item.key === this.filter.schoolYearId).value
    this.getData()
  }

  private getData(): void {
    GradeController.getGradeReports(this.filter.schoolYearId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private view(record): void {
    this.previewing = true
    const type =
      record.gradePeriodType === 'fullSchoolYear'
        ? this.campusCode[record.type] + 'Year'
        : this.campusCode[record.type]
    this.campusType = this.campusCode[record.type]
    this.reportType = record.gradePeriodType === 'fullSchoolYear' ? 'yearly' : 'term'
    operations[type](record.gradePeriodId)
      .then(res => {
        this.reportData = res.data
        this.previewVisible = true
      })
      .catch(err => console.log(err))
      .finally(() => (this.previewing = false))
  }

  private download(record) {
    const type = record.gradePeriodType === 'fullSchoolYear' ? 'yearly' : 'term'
    this.$set(record, 'downloading', true)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportReport/${token}/${schoolInfo.schoolId}/${
      record.gradePeriodId
    }/${type}/${this.campusCode[record.type]}/${moment().valueOf()}/${this.schoolYearType}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=0mm&pdf.margin.right=0mm&pdf.margin.top=4mm&waitFor=${'.loaded'}`
    // window.open(targetUrl)
    saveAs(
      url,
      `${this.studentName}_${record.gradeReportName}_${record.gradeReportEnName}.pdf`,
      record.gradePeriodId
    )
    EventBus.$once(`onload_${record.gradePeriodId}`, () => {
      this.$set(record, 'downloading', false)
    })
  }
}
