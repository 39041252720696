



















































import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator'
import { DropDownController, GradeController } from '@/services/request.service'
import PreviewModal from '@/components/Report/PreviewModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { getSchoolInfo, getToken } from '@/utils/utils'
import EventBus from '@/utils/EventBus'
import saveAs from '@/utils/FileSaver.js'
import moment from 'moment'
import { waitFor } from '@/constant/constant'

@Component({
  components: {
    FlexTooltip,
    PreviewModal,
  },
})
export default class MonthlyReport extends Vue {
  @Prop() private readonly showTitle!: any

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYears: Array<any> = []
  private data: any = []
  private previewId: any = -1
  private campusCode: any = {
    1231: 'elementary',
    1232: 'middle',
    1233: 'high',
  }
  private reportType: any = 'monthly'
  private reportData: any = {}
  private previewing: any = false
  private previewVisible: any = false
  private tipsVisible: any = true
  private schoolYearType: string = '2021-2022'
  private loading = false

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('student.reportName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'schoolYear',
        title: this.$t('common.schoolYear'),
        dataIndex: 'schoolYear',
      },
      // {
      //   key: "gradePeriod",
      //   title: this.$tc("student.gradePeriod"),
      //   dataIndex: "gradePeriodName",
      // },
      {
        dataIndex: 'type',
        title: this.$t('student.reportType'),
        scopedSlots: { customRender: 'reportType' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get studentId(): any {
    return this.$store.state.memberId ? parseInt(this.$store.state.memberId, 10) : undefined
  }

  private get campusType(): any {
    return this.$store.state.currentStudent?.campusType || '1231'
  }

  private get studentName(): any {
    const { firstName, lastName, enName, name } = this.$store.state.userInfo
    return `${lastName} ${enName} ${firstName} ${name}`
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        this.$set(this.filter, 'schoolYearId', res.data[0].key)
        this.schoolYearType = res.data[0].value
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    if (!this.studentId || !this.filter.schoolYearId || this.loading) return
    this.loading = true
    GradeController.getMonthlyReportsByStudent(this.filter.schoolYearId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private changeYear(): void {
    this.schoolYearType = this.schoolYears.find(item => item.key === this.filter.schoolYearId).value
    this.getData()
  }

  private view(record): void {
    this.previewing = true
    GradeController.getMonthlyReport(this.studentId, record.gradePeriodId)
      .then(res => {
        this.reportData = res.data
        this.previewVisible = true
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.previewing = false
      })
  }

  private download(record) {
    this.$set(record, 'downloading', true)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportMonthlyReport/${token}/${schoolInfo.schoolId}/${this.studentId}/${record.gradePeriodId}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=3.5mm&pdf.margin.right=4mm&pdf.margin.top=28mm&waitFor=${'.loaded'}`
    // window.open(targetUrl)

    const reportName = record.name + '报告单'
    const reportEnName = 'Report for ' + record.enName + '.pdf'
    const name = this.studentName + '_' + reportName + '_' + reportEnName
    saveAs(url, `${name}`, record.studentId)
    EventBus.$once(`onload_${record.studentId}`, () => {
      this.$set(record, 'downloading', false)
    })
  }
}
