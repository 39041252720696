

























import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import Report from '@/components/Report/ElementaryReport.vue'
import YearlyReport from '@/components/Report/YearlyReport.vue'
import MonthlyReport from '@/components/Report/MonthlyReport.vue'

@Component({
  components: {
    Report,
    YearlyReport,
    MonthlyReport,
  },
})
export default class PreviewModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly data!: any
  @Prop() private readonly campusType!: any
  @Prop() private readonly type!: any
  @Prop() private readonly schoolYearType!: any

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
